import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import Rating from "react-rating";

import PretendardText from "../../atoms/text/pretendardText";
import MainBorderBtn from "../../atoms/button/mainBorderBtn";
import MainBtn from "../../atoms/button/mainBtn";
import Select from "../../atoms/select/select";

import useDisplay from "../../../hooks/useDisplay";
import { route } from "../../../router/route";
import { toast } from "react-toastify";
import MerchApi from "../../../api/merchApi";
import { errorHandler } from "../../../util/errorHandler";
import CartApi from "../../../api/cartApi";
import { headerTriggerState } from "../../../store/headerState";
import { useRecoilState } from "recoil";

function ProductDRight({ data }) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const navigation = useNavigate();
  const location = useLocation();
  const { optionInfo, amountState } = location.state || {}; // state가 없을 수도 있으므로 기본값 설정

  const [headerTriggerStateAtom, setHeaderTriggerStateAtom] =
    useRecoilState(headerTriggerState);

  const [isLogin, setIsLogin] = useState(false);
  const [amount, setAmount] = useState(1);

  const [selectOptionValue, setSelectOptionValue] = useState();
  const [selectOptionValue02, setSelectOptionValue02] = useState();

  const [selectValue01, setSelectValue01] = useState("");
  const [selectList01, setSelectList01] = useState([]);
  const [selectValue02, setSelectValue02] = useState("");
  const [selectList02, setSelectList02] = useState([]);

  const [requiredFlag01, setRequiredFlag01] = useState(false);

  const [selectedOptionList, setSelectedOptionList] = useState([]);

  useEffect(() => {
    let isLogin = sessionStorage.getItem("isLogin");

    setIsLogin(isLogin);
    optionListFunc();

    if (amountState) {
      setAmount(amountState);
    }

    if (optionInfo) {
      setSelectedOptionList(optionInfo);
    }
  }, []);

  useEffect(() => {
    setSelectList02([]);
    setSelectValue02("");

    if (selectValue01) {
      optionValueListFunc();
    }
  }, [selectValue01]);

  useEffect(() => {
    if (selectValue02) {
      setSelectValue01("");
      setSelectList02([]);
      setSelectValue02("");

      if (
        selectedOptionList.some(
          (item) => item.optionValue.id === selectOptionValue02.id
        )
      ) {
        toast("Đã được chọn.");
        return;
      }

      let temp = {
        option: {
          title: selectValue01,
          id: selectOptionValue.id,
        },
        optionValue: {
          id: selectOptionValue02.id,
          title: selectOptionValue02.label,
          quantity: 1,
          price: selectOptionValue02.price, // 수량에 따라서 금액 계산
          originPrice: selectOptionValue02.price,
        },
      };

      setSelectedOptionList([...selectedOptionList, temp]);
    }
  }, [selectValue02]);

  function calculateDiscountRate(originalPrice, salePrice) {
    if (originalPrice <= 0) {
      throw new Error("Giá gốc phải lớn hơn 0");
    }

    const discountRate = ((originalPrice - salePrice) / originalPrice) * 100;

    // 소수점 첫째 자리에서 반올림
    return Math.round(discountRate);
  }

  const optionListFunc = async () => {
    try {
      const response = (await MerchApi.getOption(data.id)).data.data;

      if (response.content && response.content.length > 0) {
        let temp = response.content.map((item) => {
          return {
            id: item.id,
            label: item.title,
            value: item.title,
            requiredFlag: item.requiredFlag,
          };
        });

        setRequiredFlag01(
          response.content.some((item) => item.requiredFlag === true)
        );
        setSelectList01(temp);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const optionValueListFunc = async () => {
    try {
      const response = (await MerchApi.getOptionValue(selectOptionValue.id))
        .data.data;

      if (response.content && response.content.length > 0) {
        let temp = response.content.filter(
          (item) => item.merchStatus === "PROGRESS"
        );

        let temp02 = temp.map((item) => {
          return {
            id: item.id,
            label: item.title,
            value: item.title,
            price: item.price,
          };
        });

        setSelectList02(temp02);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const updateQuantityAndPrice = (id, newQuantity, newPrice) => {
    const updatedData = selectedOptionList.map((item) => {
      if (item.optionValue.id === id) {
        // id가 2인 경우 quantity와 price를 업데이트
        return {
          ...item,
          optionValue: {
            ...item.optionValue,
            quantity: newQuantity,
            price: newPrice,
          },
        };
      }
      return item;
    });

    setSelectedOptionList(updatedData);
  };

  const cartFunc = async (param) => {
    try {
      if (data.optionFlag) {
      } else {
        if (data.inventory == 0) {
          toast("Sản phẩm đã hết hàng.");
          return;
        }
      }
      const response = (await CartApi.cartTrigger(data.id, param)).data.data;

      toast("Đã thêm vào giỏ hàng.");
      setHeaderTriggerStateAtom(!headerTriggerStateAtom);

      // setTimeout(() => {
      //   navigation(route.shop_basket);
      // }, 400);
    } catch (error) {
      if (error?.response?.data?.message === "해당상품의 재고가 없습니다.") {
        return toast(`Sản phẩm đã hết hàng.`);
      }
      return toast(`Sản phẩm đã có trong giỏ hàng.`);
    }
  };

  return (
    <>
      <div
        style={{
          marginLeft: isSmall ? 0 : 20,
          width: isMobile ? "100%" : isTablet ? 640 : 500,
          marginTop: isSmall ? 40 : 0,
        }}
      >
        <PretendardText
          style={{
            fontSize: isMobile ? 12 : 16,
            lineHeight: isMobile ? "16.8px" : "23.2px",
            fontWeight: 600,
            color: "#939393",
            marginBottom: 8,
          }}
        >
          {data.brand}
        </PretendardText>
        <PretendardText
          style={{
            fontSize: isMobile ? 18 : 24,
            lineHeight: isMobile ? "26.1px" : "33.6px",
            fontWeight: 600,
            color: "#313131",
            marginBottom: 8,
          }}
        >
          {data.title}
        </PretendardText>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 14 : 16,
              lineHeight: isMobile ? "19.6px" : "23.2px",
              fontWeight: 600,
              color: "#313131",
              marginRight: 5,
            }}
          >
            {data.reviewAverage}
          </PretendardText>
          <Rating
            initialRating={Number(data.reviewAverage)}
            readonly
            emptySymbol={
              <img
                src="/assets/icons/rating_d.png"
                className="icon"
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            }
            fullSymbol={
              <img
                src="/assets/icons/rating_a.png"
                className="icon"
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            }
          />
          <PretendardText
            style={{
              fontSize: isMobile ? 14 : 16,
              lineHeight: isMobile ? "19.6px" : "23.2px",
              fontWeight: 500,
              color: "#939393",
              marginLeft: 20,
            }}
          >
            {data.reviewCount} bài review
          </PretendardText>
        </div>

        <DivisionSection $isMobile={isMobile} />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 20 : 26,
              lineHeight: isMobile ? "28px" : "36.4px",
              fontWeight: 700,
              color: "#313131",
              marginRight: 8,
            }}
          >
            {Number(data.salePrice).toLocaleString()}đ
          </PretendardText>

          <PretendardText
            style={{
              fontSize: isMobile ? 14 : 18,
              lineHeight: isMobile ? "20.3px" : "26.1px",
              fontWeight: 400,
              color: "#808080",
              textDecoration: "line-through",
              marginRight: 8,
            }}
          >
            {Number(data.originalPrice).toLocaleString()}đ
          </PretendardText>

          {Number(data.originalPrice) > Number(data.salePrice) ? (
            <PretendardText
              style={{
                fontSize: isMobile ? 20 : 26,
                lineHeight: isMobile ? "28px" : "36.4px",
                fontWeight: 700,
                color: "#FF649C",
              }}
            >
              {calculateDiscountRate(
                Number(data.originalPrice),
                Number(data.salePrice)
              )}
              %
            </PretendardText>
          ) : null}
        </div>

        <DivisionSection $isMobile={isMobile} />

        {/* <ItemContainer $isMobile={isMobile}>
          <ItemTitle $isMobile={isMobile}>Vận chuyển</ItemTitle>
          <ItemContent $isMobile={isMobile}>
            {Number(data.deliveryCost).toLocaleString()}đ
          </ItemContent>
        </ItemContainer>
        <ItemContainer
          $isMobile={isMobile}
          style={{
            marginBottom: 0,
          }}
        >
          <ItemTitle $isMobile={isMobile}>Đơn vị vận chuyển</ItemTitle>
          <ItemContent $isMobile={isMobile}>{data.deliveryCompany}</ItemContent>
        </ItemContainer> */}
        <ItemContainer $isMobile={isMobile}>
          <ItemTitle $isMobile={isMobile}>Cam kết hàng chính hãng</ItemTitle>
          <ItemContent $isMobile={isMobile}>Vận chuyển toàn quốc</ItemContent>
        </ItemContainer>

        {/* <ItemContainer $isMobile={isMobile}>
          <ItemTitle $isMobile={isMobile}>Vận chuyển kết hợp</ItemTitle>
          <ItemContent $isMobile={isMobile}>
            {data.bundled ? "Đúng rồi" : "Không"}
          </ItemContent>
        </ItemContainer> */}

        <DivisionSection $isMobile={isMobile} />

        <PretendardText
          style={{
            fontSize: 18,
            lineHeight: "26.1px",
            fontWeight: 600,
            color: "#313131",
            marginBottom: 24,
          }}
        >
          Thông tin sản phẩm
        </PretendardText>

        <ItemContainer $isMobile={isMobile}>
          <ItemTitle $isMobile={isMobile}>Nhà sản xuất</ItemTitle>
          <ItemContent $isMobile={isMobile}>{data.manufacturer}</ItemContent>
        </ItemContainer>
        <ItemContainer $isMobile={isMobile}>
          <ItemTitle $isMobile={isMobile}>Thương hiệu</ItemTitle>
          <ItemContent $isMobile={isMobile}>{data.brand}</ItemContent>
        </ItemContainer>
        <ItemContainer $isMobile={isMobile}>
          <ItemTitle $isMobile={isMobile}>Xuất xứ</ItemTitle>
          <ItemContent $isMobile={isMobile}>{data.madeIn}</ItemContent>
        </ItemContainer>
        <ItemContainer
          style={{
            marginBottom: 0,
          }}
          $isMobile={isMobile}
        >
          <ItemTitle $isMobile={isMobile}>Loại</ItemTitle>
          <ItemContent $isMobile={isMobile}>{data.productType}</ItemContent>
        </ItemContainer>

        <DivisionSection $isMobile={isMobile} />

        {data.optionFlag ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 700,
                }}
              >
                Phân loại
              </PretendardText>
              {requiredFlag01 ? (
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#FF3D83",
                    fontWeight: 700,
                  }}
                >
                  *
                </PretendardText>
              ) : null}
            </div>
            <Select
              style={{}}
              setValue={setSelectValue01}
              value={selectValue01}
              dataList={selectList01}
              placeholder={"Hãy lựa chọn tùy chọn."}
              setAllValue={setSelectOptionValue}
            />

            {selectValue01 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 20,
                    marginBottom: 8,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#313131",
                      fontWeight: 700,
                    }}
                  >
                    {selectValue01}
                  </PretendardText>
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#FF3D83",
                      fontWeight: 700,
                    }}
                  >
                    *
                  </PretendardText>
                </div>
                <Select
                  style={{ zIndex: 90 }}
                  setValue={setSelectValue02}
                  value={selectValue02}
                  dataList={selectList02}
                  placeholder={"Hãy lựa chọn tùy chọn."}
                  setAllValue={setSelectOptionValue02}
                />
              </>
            ) : null}
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 700,
                }}
              >
                Số lượng
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#FF3D83",
                  fontWeight: 700,
                }}
              >
                *
              </PretendardText>
            </div>
            <div
              style={{
                width: "100%",
                border: "2px solid #E8E8E8",
                borderRadius: 8,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 55,
                padding: "0px 15px",
              }}
            >
              <img
                onClick={() => {
                  if (amount == 1) {
                    return;
                  }

                  setAmount(amount - 1);
                }}
                style={{
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                }}
                src="/assets/icons/btn-count-minus-24.png"
                alt="btn-count-minus-24"
              />
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 500,
                  margin: "0px 20px",
                }}
              >
                {amount}
              </PretendardText>
              <img
                onClick={() => {
                  setAmount(amount + 1);
                }}
                style={{
                  width: 24,
                  height: 24,
                  cursor: "pointer",
                }}
                src="/assets/icons/btn-count-plus-24.png"
                alt="btn-count-plus-24"
              />
            </div>
          </>
        )}

        {selectedOptionList.length > 0 ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 700,
                }}
              >
                Số lượng
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#FF3D83",
                  fontWeight: 700,
                }}
              >
                *
              </PretendardText>
            </div>
            {selectedOptionList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    padding: 15,
                    border: "1px solid #E8E8E8",
                    borderRadius: 8,
                    display: "flex",
                    flexDirection: "column",
                    marginBottom:
                      selectedOptionList.length === index + 1 ? 0 : 12,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      lineHeight: "23.2px",
                      color: "#313131",
                      fontWeight: 500,
                      marginBottom: 14,
                    }}
                  >
                    {item.option.title} {item.optionValue.title}
                  </PretendardText>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        onClick={() => {
                          if (item.optionValue.quantity == 1) {
                            return;
                          }

                          updateQuantityAndPrice(
                            item.optionValue.id,
                            item.optionValue.quantity - 1,
                            (item.optionValue.quantity - 1) *
                              item.optionValue.originPrice
                          );
                        }}
                        style={{
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        }}
                        src="/assets/icons/btn-count-minus-24.png"
                        alt="btn-count-minus-24"
                      />
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "23.2px",
                          color: "#313131",
                          fontWeight: 500,
                          margin: "0px 20px",
                        }}
                      >
                        {item.optionValue.quantity}
                      </PretendardText>
                      <img
                        onClick={() => {
                          updateQuantityAndPrice(
                            item.optionValue.id,
                            item.optionValue.quantity + 1,
                            (item.optionValue.quantity + 1) *
                              item.optionValue.originPrice
                          );
                        }}
                        style={{
                          width: 24,
                          height: 24,
                          cursor: "pointer",
                        }}
                        src="/assets/icons/btn-count-plus-24.png"
                        alt="btn-count-plus-24"
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <PretendardText
                        style={{
                          fontSize: 16,
                          lineHeight: "23.2px",
                          color: "#313131",
                          fontWeight: 500,
                          margin: "0px 15px",
                        }}
                      >
                        {Number(item.optionValue.price).toLocaleString()}đ
                      </PretendardText>
                      <img
                        onClick={() => {
                          let temp = selectedOptionList.filter(
                            (item02) =>
                              item02.optionValue.id !== item.optionValue.id
                          );

                          setSelectedOptionList(temp);
                        }}
                        style={{
                          width: 22,
                          height: 22,
                          cursor: "pointer",
                        }}
                        src="/assets/icons/ic-closed-22.png"
                        alt="ic-closed-22"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: isSmall ? 0 : 31,
            position: isSmall ? "fixed" : "relative",
            bottom: 0,
            left: isMobile ? 0 : "auto",
            zIndex: isSmall ? 92 : 89,
            width: "100%",
            background: "#fff",
            padding: isMobile ? "10px 20px" : isTablet ? "10px 0px" : 0,
            justifyContent: isMobile ? "space-between" : "flex-start",
          }}
        >
          <MainBorderBtn
            onClick={async () => {
              if (isLogin) {
                if (data.optionFlag) {
                  if (requiredFlag01) {
                    let requiredOption = selectList01.find(
                      (item) => item.requiredFlag === true
                    );

                    if (
                      !selectedOptionList.some(
                        (item) => item.option.title === requiredOption.label
                      )
                    ) {
                      toast("Hãy lựa chọn phân loại.");
                      return;
                    }
                  }
                } else {
                  if (amount == 0) {
                    toast("Hãy lựa chọn số lượng.");
                    return;
                  }
                }

                const orderInfo = {
                  noOptionQuantity: amount,
                  optionInfo: JSON.stringify(selectedOptionList),
                };

                await cartFunc(orderInfo);
              } else {
                navigation(route.login);
              }
            }}
            style={{
              marginRight: isMobile ? 0 : 20,
              width: isMobile ? "48%" : isTablet ? 310 : "100%",
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 700,
              }}
            >
              Giỏ hàng
            </PretendardText>
          </MainBorderBtn>
          <MainBtn
            onClick={() => {
              if (isLogin) {
                if (data.optionFlag) {
                  if (requiredFlag01) {
                    let requiredOption = selectList01.find(
                      (item) => item.requiredFlag === true
                    );

                    if (
                      !selectedOptionList.some(
                        (item) => item.option.title === requiredOption.label
                      )
                    ) {
                      toast("Hãy lựa chọn phân loại.");
                      return;
                    }
                  }
                } else {
                  if (amount == 0) {
                    toast("Hãy lựa chọn số lượng.");
                    return;
                  }
                }

                let merchInfoTemp = [
                  {
                    merch: {
                      id: data.id,
                      title: data.title,
                      salePrice: data.salePrice,
                      originalPrice: data.originalPrice,
                      deliveryCost: data.deliveryCost,
                      imageOriginFileName: data.images[0].imageOriginFileName,
                      imageServerFileName: data.images[0].imageServerFileName,
                      optionFlag: data.optionFlag, // 옵션있으면 true
                      noOptionQuantity: amount, // 옵션이 없을 경우 주문수량
                      weight: data.weight,
                      bundled: data.bundled,
                    },
                    optionInfo: selectedOptionList,
                  },
                ];

                sessionStorage.setItem(
                  "orderInfo",
                  JSON.stringify(merchInfoTemp)
                );

                navigation(`${route.product_payment}`);
              } else {
                navigation(route.login);
              }
            }}
            style={{
              width: isMobile ? "48%" : isTablet ? 310 : "100%",
            }}
          >
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "white",
                fontWeight: 700,
              }}
            >
              Mua hàng
            </PretendardText>
          </MainBtn>
        </div>
      </div>
    </>
  );
}

export default ProductDRight;

const DivisionSection = styled.div`
  width: 100%;
  height: 1px;
  margin: ${(props) => (props.$isMobile ? "18px 0px" : "23px 0px")};
  background-color: #c4c4c4;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.$isMobile ? "space-between" : "flex-start"};
  margin-bottom: 12px;
`;

const ItemTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "16px")};
  line-height: ${(props) => (props.$isMobile ? "19.6px" : "23.2px")};
  font-weight: 500;
  color: rgb(49, 49, 49);
  width: ${(props) => (props.$isMobile ? "auto" : "125px")};
  margin-right: ${(props) => (props.$isMobile ? "0px" : "25px")};
`;

const ItemContent = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "14px" : "16px")};
  line-height: ${(props) => (props.$isMobile ? "19.6px" : "23.2px")};
  font-weight: 500;
  color: rgb(98, 98, 98);
`;
