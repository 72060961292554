import { useEffect, useState } from "react";

import PretendardText from "../../atoms/text/pretendardText";

import useDisplay from "../../../hooks/useDisplay";
import moment from "moment";

function ExchangeCard({
  onClick,
  title,
  subTitle,
  style,
  returnType,
  address,
  date,
  orderInfo,
}) {
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const [couponInfo, setCouponInfo] = useState(
    JSON.parse(orderInfo.couponInfo)
  );

  const dayschangeFunc = (days) => {
    switch (days) {
      case 0:
        return "chủ nhật";
      case 1:
        return "thứ hai";
      case 2:
        return "thứ ba";
      case 3:
        return "thứ tư";
      case 4:
        return "thứ năm";
      case 5:
        return "thứ sáu";
      case 6:
        return "thứ bảy";
    }
  };

  return (
    <>
      <div
        style={{
          padding: "20px 24px",
          border: "1px solid #EEEEEE",
          borderRadius: 8,
          marginBottom: 20,
          ...style,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <PretendardText
            style={{
              fontSize: 16,
              lineHeight: "23.2px",
              color: "#313131",
              fontWeight: 600,
            }}
          >
            {title}
          </PretendardText>
          {returnType ? null : (
            <PretendardText
              onClick={onClick}
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#0185FF",
                fontWeight: 400,
                cursor: "pointer",
              }}
            >
              Thay đổi
            </PretendardText>
          )}
        </div>

        {returnType ? (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                  minWidth: 120,
                  maxWidth: 120,
                  marginRight: 20,
                }}
              >
                Tổng tiền hàng
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                }}
              >
                {Number(orderInfo.orderAmount).toLocaleString()} đồng
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                  minWidth: 120,
                  maxWidth: 120,
                  marginRight: 20,
                }}
              >
                Phí giao hàng
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                }}
              >
                {Number(orderInfo.deliveryAmount).toLocaleString()} đồng
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                  minWidth: 120,
                  maxWidth: 120,
                  marginRight: 20,
                }}
              >
                phiếu mua hàng
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                }}
              >
                {Number(orderInfo.couponAmount).toLocaleString()} đồng
              </PretendardText>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                  minWidth: 120,
                  maxWidth: 120,
                  marginRight: 20,
                }}
              >
                điểm nhấn
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                }}
              >
                {Number(orderInfo.pointAmount).toLocaleString()} P
              </PretendardText>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: 8,
              }}
            >
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                  marginRight: 20,
                }}
              >
                {address.name}
              </PretendardText>
              <PretendardText
                style={{
                  fontSize: 14,
                  lineHeight: "19.6px",
                  color: "#626262",
                  fontWeight: 400,
                }}
              >
                {address.phone}
              </PretendardText>
            </div>
            <PretendardText
              style={{
                fontSize: 14,
                lineHeight: "19.6px",
                color: "#626262",
                fontWeight: 400,
              }}
            >
              {address.addressDetail} {address.address}
            </PretendardText>
          </>
        )}

        <div
          style={{
            height: 1,
            width: "100%",
            background: "#E8E8E8",
            margin: isMobile ? "16px 0px" : "24px 0px",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <PretendardText
            style={{
              fontSize: 16,
              lineHeight: "23.2px",
              color: "#313131",
              fontWeight: 600,
              marginBottom: isMobile ? 8 : 0,
            }}
          >
            {subTitle}
          </PretendardText>
          {returnType ? (
            <div>
              <PretendardText
                style={{
                  fontSize: 16,
                  lineHeight: "23.2px",
                  color: "#313131",
                  fontWeight: 400,
                  marginBottom: 8,
                  textAlign: isMobile ? "left" : "right",
                }}
              >
                {Number(orderInfo.payAmount).toLocaleString()} đồng
              </PretendardText>
              {couponInfo && couponInfo.length > 0
                ? couponInfo.map((item, index) => {
                    return (
                      <PretendardText
                        key={index}
                        style={{
                          fontSize: 16,
                          lineHeight: "23.2px",
                          color: "#313131",
                          fontWeight: 400,
                          textAlign: isMobile ? "left" : "right",
                        }}
                      >
                        {item.title}
                      </PretendardText>
                    );
                  })
                : null}
            </div>
          ) : (
            <PretendardText
              style={{
                fontSize: 16,
                lineHeight: "23.2px",
                color: "#313131",
                fontWeight: 400,
              }}
            >
              {date}({dayschangeFunc(moment(date).days())})
            </PretendardText>
          )}
        </div>
      </div>
    </>
  );
}

export default ExchangeCard;
