import axios from "axios";
import { route } from "../router/route";

// 스웨거 주소
// http://192.168.1.10:8080/api/swagger

// const baseURL = `http://192.168.1.10:8080/`;
const baseURL = `https://api.koviet.vn/`; //실주소
// const baseURL = `http://223.130.155.251:8081/`; //테스트 주소

export const _axios = axios.create({
  baseURL,
  withCredentials: true,
});

let alertFlag = true;
let debounceTimer = null; // 디바운스를 위한 변수

_axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { response, config } = error;

    // 액세스 토큰 만료
    if (response.status === 403) {
      try {
        const response = await api.post(`auth/member/token/refresh`);
        const newToken = response.data.data.accessToken;
        // new 액세스 토큰 변수 저장
        sessionStorage.setItem("token", newToken);

        // new 액세스 토큰 헤더 설정
        config.headers["authorization"] = `Bearer ${newToken}`;
        return await _axios(config);
      } catch (error) {
        sessionStorage.clear();
        window.location.href = route.login + "?refresh=true";
        if (alertFlag) {
          alert("Vui lòng đăng nhập.");
          alertFlag = false;
        }
      }
    } else if (response.status === 401) {
      // 리프레쉬 토큰 만료
      sessionStorage.clear();
      window.location.href = route.login + "?refresh=true";

      if (alertFlag) {
        alert("Vui lòng đăng nhập.");
        alertFlag = false;
      }
    } else if (response.status === 406) {
      if (!debounceTimer) {
        sessionStorage.clear();
        window.location.href = route.login;

        debounceTimer = setTimeout(() => {
          debounceTimer = null;
        }, 3000); // 3초 동안 중복 실행 방지
      }
    }

    return Promise.reject(error);
  }
);

export default class api {
  static async getAuthorizeHeader() {
    // const token = accessToken;
    const token = sessionStorage.getItem("token");

    return token
      ? {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        }
      : {};
  }

  static async send({ method, url, data, params, headers }) {
    try {
      const res = await _axios.request({
        url,
        method,
        params,
        data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          ...(await api.getAuthorizeHeader()),
          ...headers,
        },
      });

      return { data: res.data, status: res.status };
    } catch (ex) {
      throw ex;
    }
  }

  static async get(url, params, headers) {
    return await api.send({ method: "GET", url, params, headers });
  }

  static async post(url, data, headers) {
    return await api.send({ method: "POST", url, data, headers });
  }

  static async put(url, data, headers) {
    return await api.send({ method: "PUT", url, data, headers });
  }

  static async patch(url, data, headers) {
    return await api.send({ method: "PATCH", url, data, headers });
  }

  static async del(url, data, headers) {
    return await api.send({ method: "DELETE", url, data, headers });
  }

  static async fileForm(url, file, method, params) {
    let formData = new FormData();
    formData.append("file", file);

    return await api.send({
      url,
      method: method || "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      params: params,
    });
  }
}
