import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { sha256, sha224 } from "js-sha256";

import PretendardText from "../../components/atoms/text/pretendardText";
import PCContainer from "../../components/atoms/layout/pcContainer";
import FooterBtnGroup from "../../components/templates/footerBtnGroup";
import ProductPaymentRight from "../../components/templates/product/productPaymentRight";
import RightLabelCheckbox from "../../components/molecules/checkbox/rightLabelCheckbox";
import PaymentProduct from "../../components/organisms/product/paymentProduct";
import Input from "../../components/atoms/input/input";
import MainBorderBtn from "../../components/atoms/button/mainBorderBtn";
import Select from "../../components/atoms/select/select";

import { route } from "../../router/route";
import useDisplay from "../../hooks/useDisplay";
import AddressModal from "../../components/templates/modals/addressModal";
import MerchApi from "../../api/merchApi";
import { toast } from "react-toastify";
import { errorHandler } from "../../util/errorHandler";
import MemberApi from "../../api/memberApi";
import moment from "moment";
import OrderApi from "../../api/orderApi";
import EfulfillApi from "../../api/efulfillApi";

const ProductPayment = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet, isDesktop } = useDisplay();
  const isSmall = isMobile || isTablet;

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [timestamp, setTimestamp] = useState(new Date().getTime());

  const [isADModal, setIsADodal] = useState(false);
  const [reAddress, setReAddress] = useState(false);

  const [myPoint, setMyPoint] = useState("");
  const [point, setPoint] = useState("");
  const [usePoint, setUsePoint] = useState(0);
  const [pointErrorM, setPointErrorM] = useState("");
  const [selectCouponValue, setSelectCouponValue] = useState("");
  const [selectCouponAllValue, setSelectCouponAllValue] = useState();
  const [selectCouponList, setSelectCouponList] = useState([]);
  const [merchInfo, setMerchInfo] = useState();
  const [myInfo, setMyInfo] = useState(null);

  const [addressValue, setAddressValue] = useState("");
  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [wardName, setWardName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [provinceName, setProvinceName] = useState("");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [productAmount, setProductAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [calTotalAmount, setCalTotalAmount] = useState(0);
  const [couponAmount, setCouponAmount] = useState(0);

  const [minimumHold, setMinimumHold] = useState(0);
  const [minimumPayment, setMinimumPayment] = useState(0);

  const [localIp, setLocalIp] = useState("");

  useEffect(() => {
    let resultMsg = searchParams.get("resultMsg")
      ? searchParams.get("resultMsg")
      : "";
    let trxId = searchParams.get("trxId") ? searchParams.get("trxId") : "";
    let payType = searchParams.get("payType")
      ? searchParams.get("payType")
      : "";

    if (resultMsg) {
      if (resultMsg == "SUCCESS") {
        orderFunc(trxId, payType);
      } else {
        let payAtomTemp = sessionStorage.getItem("payAtom");
        let payStateAtom = JSON.parse(payAtomTemp);

        setWardName(payStateAtom.wardName);
        setDistrictName(payStateAtom.districtName);
        setProvinceName(payStateAtom.provinceName);

        setAddress(payStateAtom.address);
        setMerchInfo(payStateAtom.merchInfo);
        setAddressDetail(payStateAtom.addressDetail);
        setPhone(payStateAtom.phone);
        setName(payStateAtom.name);
        setTotalAmount(payStateAtom.totalAmount);
        // setDeliveryAmount(payStateAtom.deliveryAmount);
        setUsePoint(payStateAtom.usePoint);
        setCouponAmount(payStateAtom.couponAmount);
        setSelectCouponAllValue(payStateAtom.selectCouponAllValue);
        setCalTotalAmount(payStateAtom.calTotalAmount);
      }
    }
  }, [location]);

  useEffect(() => {
    couponCalFunc();
  }, [totalAmount, usePoint, selectCouponAllValue]);

  const couponCalFunc = () => {
    let couponAmountTemp = 0;
    let totalAmountTemp = totalAmount - Number(usePoint);
    if (selectCouponAllValue) {
      switch (selectCouponAllValue.coupon.couponType) {
        case "DELIVERY":
          setCalTotalAmount(totalAmountTemp - Number(deliveryAmount));
          setCouponAmount(Number(deliveryAmount));

          break;
        case "RATIO":
          couponAmountTemp = calculateDiscountedTotal(
            totalAmount,
            Number(selectCouponAllValue.coupon.amount)
          );
          setCalTotalAmount(totalAmountTemp - Number(couponAmountTemp));
          setCouponAmount(Number(couponAmountTemp));
          break;

        default:
          couponAmountTemp = Number(selectCouponAllValue.coupon.amount);
          setCalTotalAmount(totalAmountTemp - Number(couponAmountTemp));
          setCouponAmount(Number(couponAmountTemp));
          break;
      }
    } else {
      setCalTotalAmount(totalAmountTemp);
    }
  };

  // totalAmount: 총 금액, selectCouponAllValue.coupon.amount: 할인율 (%)
  function calculateDiscountedTotal(totalAmount, discountPercentage) {
    // 할인된 금액을 계산하고 소수점은 올림 처리
    const discountAmount = Math.ceil(totalAmount * (discountPercentage / 100));

    return discountAmount;
  }

  useEffect(() => {
    const getLocalIP = async () => {
      const peerConnection = new RTCPeerConnection({
        iceServers: [],
      });

      peerConnection.createDataChannel("");
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);

      peerConnection.onicecandidate = (event) => {
        if (event && event.candidate) {
          const candidate = event.candidate.candidate;
          const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
          const ipMatch = candidate.match(ipRegex);
          if (ipMatch) {
            setLocalIp(ipMatch[1]);
            peerConnection.close();
          }
        }
      };
    };

    getLocalIP();
  }, []);

  useEffect(() => {
    let orderInfo = sessionStorage.getItem("orderInfo");

    merchDetailFunc(JSON.parse(orderInfo));
    myInfoFunc();
    myCouponListFunc();
    pointSettingFunc();

    // jQuery나 다른 외부 스크립트들이 로드된 후 `openPayment` 함수를 사용 가능
    if (typeof window.openPayment !== "function") {
      console.error("openPayment 함수가 아직 로드되지 않았습니다.");
    }
  }, []);

  useEffect(() => {
    if (point.length > 0) {
      let regex = new RegExp("^[0-9]+$");
      if (!regex.test(point)) {
        return setPointErrorM("Vui lòng nhập số.");
      }

      setPointErrorM("");
    }
  }, [point]);

  useEffect(() => {
    if (merchInfo && address && myInfo) {
      let isBundled = merchInfo.filter((item) => item.merch.bundled === true);
      let isBundledWeight = 0;
      isBundled.map((item) => {
        isBundledWeight += item.merch.weight;
      });

      let isBundledWeightTotal = convertToKg(isBundledWeight);
      deliveryAmountCal(isBundledWeightTotal);

      let isNotBundled = merchInfo.filter(
        (item) => item.merch.bundled === false
      );

      if (isNotBundled.length > 0) {
        isNotBundled.map((item) => {
          deliveryAmountCal(item.merch.weight);
        });
      }
    }
  }, [merchInfo, address, myInfo]);

  function convertToKg(weightInGrams) {
    return Math.ceil(weightInGrams / 1000); // g에서 kg으로 변환 후 소수점 이하 올림
  }

  const deliveryAmountCal = async (weight) => {
    try {
      let addressTemp = [];
      if (JSON.parse(myInfo?.address)?.length > 0) {
        addressTemp = JSON.parse(myInfo.address);
      } else {
        addressTemp = JSON.parse(addressValue);
      }

      await EfulfillApi.login();
      const response = await EfulfillApi.getDeliveryAmount(
        addressTemp,
        weight == 0 || !weight ? 1 : weight
      );

      if (response?.data?.result?.data[0]?.shippingFee) {
        let shippingFeeTemp = response?.data?.result?.data[0]?.shippingFee;
        let shippingFeeTempTotal = deliveryAmount + shippingFeeTemp;

        setDeliveryAmount(shippingFeeTempTotal);
        setTotalAmount(totalAmount + shippingFeeTempTotal);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayment = () => {
    orderFuncCheck();
  };

  const merchDetailFunc = async (merchInfo) => {
    try {
      let merchInfoTemp = merchInfo;

      let amount = 0;

      let productCost = 0;

      for (let i = 0; i < merchInfo.length; i++) {
        let eachAmount = 0;

        if (merchInfo[i].merch.optionFlag) {
          for (let j = 0; j < merchInfo[i].optionInfo.length; j++) {
            eachAmount += merchInfo[i].optionInfo[j].optionValue.price;
          }

          eachAmount += merchInfo[i].merch.salePrice;
        } else {
          eachAmount +=
            merchInfo[i].merch.noOptionQuantity * merchInfo[i].merch.salePrice;
        }

        merchInfoTemp[i].merch.payAmount = eachAmount;

        amount += eachAmount;
      }

      productCost = amount;

      // amount 상품 금액
      // deliveryCost 배송비
      // productCost 상품금액 + 배송비

      setProductAmount(amount);
      setTotalAmount(productCost);

      setMerchInfo(merchInfoTemp);
    } catch (error) {}
  };

  const myInfoFunc = async () => {
    try {
      const myInfoRP = (await MemberApi.getMyInfo()).data.data.content;

      setMyInfo(myInfoRP);
      setMyPoint(myInfoRP.point);

      let addressArray = myInfoRP.address ? JSON.parse(myInfoRP.address) : [];
      let addressTemp = "";

      if (addressArray[2]) {
        addressTemp += `${addressArray[2].wardName}`;
        setWardName(addressArray[2].wardName);
      }

      if (addressArray[1]) {
        addressTemp += ` ${addressArray[1].districtName}`;
        setDistrictName(addressArray[1].districtName);
      }

      if (addressArray[0]) {
        addressTemp += ` ${addressArray[0].provinceName}`;
        setProvinceName(addressArray[0].provinceName);
      }

      setAddress(addressTemp);
      setAddressDetail(myInfoRP.detailAddress);
      setName(myInfoRP.recipient);
      setPhone(myInfoRP.phoneNumber);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const pointSettingFunc = async () => {
    try {
      const response = (await OrderApi.pointSetting()).data.data.content;

      setMinimumHold(response.minimumHold);
      setMinimumPayment(response.minimumPayment);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const myCouponListFunc = async () => {
    try {
      const response = (await MemberApi.getMyCouponPossible()).data.data
        .content;

      let temp02 = response.map((item) => {
        return {
          ...item,
          label:
            item.coupon.couponType === "DELIVERY"
              ? item.coupon.title
              : item.coupon.couponType === "RATIO"
              ? `${item.coupon.title} ${Number(
                  item.coupon.amount
                ).toLocaleString()}%`
              : `${item.coupon.title} ${Number(
                  item.coupon.amount
                ).toLocaleString()}`,
          value:
            item.coupon.couponType === "DELIVERY"
              ? item.coupon.title
              : item.coupon.couponType === "RATIO"
              ? `${item.coupon.title} ${Number(
                  item.coupon.amount
                ).toLocaleString()}%`
              : `${item.coupon.title} ${Number(
                  item.coupon.amount
                ).toLocaleString()}`,
        };
      });

      setSelectCouponList(temp02);
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const addressChangeFunc = (ad, adD, rep, pho, isBase) => {
    let addressTemp = "";

    if (ad[2]) {
      addressTemp += `${ad[2].wardName}`;
      setWardName(ad[2].wardName);
    }

    if (ad[1]) {
      addressTemp += ` ${ad[1].districtName}`;
      setDistrictName(ad[1].districtName);
    }

    if (ad[0]) {
      addressTemp += ` ${ad[0].provinceName}`;
      setProvinceName(ad[0].provinceName);
    }

    setAddressValue(JSON.stringify(ad));
    setAddress(addressTemp);
    setAddressDetail(adD);
    setName(rep);
    setPhone(pho);

    if (isBase) {
      deliveryChangeFunc(ad, adD, rep, pho);
    }

    setIsADodal(false);
  };

  const deliveryChangeFunc = async (ad, adD, rep, pho) => {
    try {
      const params = {
        address: JSON.stringify(ad),
        detailAddress: adD ? adD : addressDetail,
        recipient: rep ? rep : name,
        phoneNumber: pho ? pho : phone,
      };

      await MemberApi.updateAddressInfo(myInfo?.id, params);
      await myInfoFunc();
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      // errorHandler(error);
    }
  };

  const orderFuncCheck = async () => {
    try {
      let payStateAtom = {
        wardName: wardName,
        districtName: districtName,
        provinceName: provinceName,
        address: address,
        merchInfo: merchInfo,
        addressDetail: addressDetail,
        phone: phone,
        name: name,
        totalAmount: totalAmount,
        deliveryAmount: deliveryAmount,
        usePoint: usePoint,
        couponAmount: couponAmount,
        selectCouponAllValue: selectCouponAllValue,
        calTotalAmount: calTotalAmount,
        minimumPayment: minimumPayment,
        minimumHold: minimumHold,
      };

      if (Number(payStateAtom.usePoint) > 0) {
        if (Number(payStateAtom.usePoint) < payStateAtom.minimumHold) {
          toast(`Số tiền đặt hàng tối thiểu là ${payStateAtom.minimumHold}.`);
          return;
        }

        if (Number(payStateAtom.calTotalAmount) < payStateAtom.minimumPayment) {
          toast(
            `Khi sử dụng điểm , số tiền đặt hàng tối thiểu là ${payStateAtom.minimumPayment}.`
          );
          return;
        }
      }

      for (let i = 0; i < payStateAtom.merchInfo.length; i++) {
        if (payStateAtom.merchInfo[i].merch.optionFlag) {
          let check = false;
          for (
            let j = 0;
            j < payStateAtom.merchInfo[i].optionInfo.length;
            j++
          ) {
            check = await optionValueListFunc(
              payStateAtom.merchInfo[i].optionInfo[j].option.id,
              payStateAtom.merchInfo[i].optionInfo[j].optionValue.id,
              payStateAtom.merchInfo[i].optionInfo[j].optionValue.quantity
            );

            if (check) {
              return;
            }
          }

          if (check) {
            toast("Hết hàng.");
            return;
          }
        } else {
          if (
            payStateAtom.merchInfo[i].merch.noOptionQuantity >
            merchInventoryCheckFunc(payStateAtom.merchInfo[i].merch.id)
          ) {
            toast("Hết hàng.");
            return;
          }
        }
      }
      let couponInfo = [];

      if (payStateAtom.selectCouponAllValue) {
        couponInfo = [
          {
            id: payStateAtom.selectCouponAllValue.id,
            title: payStateAtom.selectCouponAllValue.coupon.title,
            amount: payStateAtom.couponAmount,
          },
        ];
      }

      let paymentId = `${moment().format("YYMMDD")}${Math.random()
        .toString(36)
        .slice(7)}`;

      const param = {
        merchInfo: JSON.stringify(payStateAtom.merchInfo),
        address: payStateAtom.address,
        detailAddress: payStateAtom.addressDetail,
        wardName: payStateAtom.wardName,
        districtName: payStateAtom.districtName,
        provinceName: payStateAtom.provinceName,
        phoneNumber: payStateAtom.phone,
        recipient: payStateAtom.name,
        orderAmount: payStateAtom.totalAmount - payStateAtom.deliveryAmount,
        deliveryAmount: payStateAtom.deliveryAmount,
        pointAmount: Number(payStateAtom.usePoint),
        couponAmount: Number(payStateAtom.couponAmount),
        couponInfo: JSON.stringify(couponInfo),
        payAmount: payStateAtom.calTotalAmount,
        payMethod: "CREDIT_CARD",
        cardName: "",
        paymentKey: paymentId, // 테스트 paymentId 생성해서 넣음
      };

      await OrderApi.orderCheck(param);

      let payAtom = {
        wardName: wardName,
        districtName: districtName,
        provinceName: provinceName,
        address: address,
        merchInfo: merchInfo,
        addressDetail: addressDetail,
        phone: phone,
        name: name,
        totalAmount: totalAmount,
        deliveryAmount: deliveryAmount,
        usePoint: usePoint,
        couponAmount: couponAmount,
        selectCouponAllValue: selectCouponAllValue,
        calTotalAmount: calTotalAmount,
        minimumPayment: minimumPayment,
        minimumHold: minimumHold,
      };

      sessionStorage.setItem("payAtom", JSON.stringify(payAtom));

      if (typeof window.openPayment === "function") {
        window.openPayment(1, "https://pg.megapay.vn");
      } else {
        console.error("openPayment 함수가 정의되지 않았습니다.");
      }
    } catch (error) {
      if (error?.response?.data?.message[0] === "address should not be empty") {
        return toast(`Vui lòng nhập địa chỉ vận chuyển.`);
      }
      if (
        error?.response?.data?.message?.includes("해당상품의 재고가 없습니다.")
      ) {
        const message = error.response.data.message;
        const [, productName] = message.split("/");
        return toast(
          `Sản phẩm đã hết hàng${productName ? ` (${productName})` : ""}.`
        );
      }

      if (
        error?.response?.data?.message?.includes(
          "해당상품은 판매중지상태입니다."
        )
      ) {
        const message = error.response.data.message;
        const [, productName] = message.split("/");
        return toast(
          `Sản phẩm này đang trong tình trạng ngừng bán${
            productName ? ` (${productName})` : ""
          }.`
        );
      }
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const orderFunc = async (trxId, payType) => {
    try {
      let payAtomTemp = sessionStorage.getItem("payAtom");
      let payStateAtom = JSON.parse(payAtomTemp);

      if (Number(payStateAtom.usePoint) > 0) {
        if (Number(payStateAtom.usePoint) < payStateAtom.minimumHold) {
          toast(`Số tiền đặt hàng tối thiểu là ${payStateAtom.minimumHold}.`);
          return;
        }

        if (Number(payStateAtom.calTotalAmount) < payStateAtom.minimumPayment) {
          toast(
            `Khi sử dụng điểm , số tiền đặt hàng tối thiểu là ${payStateAtom.minimumPayment}.`
          );
          return;
        }
      }

      for (let i = 0; i < payStateAtom.merchInfo.length; i++) {
        if (payStateAtom.merchInfo[i].merch.optionFlag) {
          let check = false;
          for (
            let j = 0;
            j < payStateAtom.merchInfo[i].optionInfo.length;
            j++
          ) {
            check = await optionValueListFunc(
              payStateAtom.merchInfo[i].optionInfo[j].option.id,
              payStateAtom.merchInfo[i].optionInfo[j].optionValue.id,
              payStateAtom.merchInfo[i].optionInfo[j].optionValue.quantity
            );

            if (check) {
              return;
            }
          }

          if (check) {
            toast("Hết hàng.");
            return;
          }
        } else {
          if (
            payStateAtom.merchInfo[i].merch.noOptionQuantity >
            merchInventoryCheckFunc(payStateAtom.merchInfo[i].merch.id)
          ) {
            toast("Hết hàng.");
            return;
          }
        }
      }
      let couponInfo = [];

      if (payStateAtom.selectCouponAllValue) {
        couponInfo = [
          {
            id: payStateAtom.selectCouponAllValue.id,
            title: payStateAtom.selectCouponAllValue.coupon.title,
            amount: payStateAtom.couponAmount,
          },
        ];
      }

      const param = {
        merchInfo: JSON.stringify(payStateAtom.merchInfo),
        wardName: payStateAtom.wardName,
        districtName: payStateAtom.districtName,
        provinceName: payStateAtom.provinceName,
        address: payStateAtom.address,
        detailAddress: payStateAtom.addressDetail,
        phoneNumber: payStateAtom.phone,
        recipient: payStateAtom.name,
        orderAmount: payStateAtom.totalAmount - payStateAtom.deliveryAmount,
        deliveryAmount: payStateAtom.deliveryAmount,
        pointAmount: Number(payStateAtom.usePoint),
        couponAmount: Number(payStateAtom.couponAmount), // 아직 쿠폰 데이터 없음
        couponInfo: JSON.stringify(couponInfo), // 아직 쿠폰 데이터 없음
        payAmount: payStateAtom.calTotalAmount,
        payMethod: "CREDIT_CARD",
        cardName: "",
        paymentKey: trxId, // trxId
        payType: payType,
      };

      const response = await OrderApi.order(param);

      sessionStorage.removeItem("orderInfo");
      sessionStorage.removeItem("payAtom");

      toast("Đã hoàn tất thanh toán.");
      navigate(`${route.mypage_order_list}/1`);
    } catch (error) {
      if (error?.response?.data?.message[0] === "address should not be empty") {
        return toast(`Vui lòng nhập địa chỉ vận chuyển.`);
      }
      if (error?.response?.data?.message === "해당상품의 재고가 없습니다.") {
        return toast(`Sản phẩm đã hết hàng.`);
      }
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const optionValueListFunc = async (optionId, optionValueId, amount) => {
    try {
      const response = (await MerchApi.getOptionValue(optionId)).data.data
        .content;

      let temp = response.find((item) => item.id === optionValueId);

      if (amount > temp.inventory) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  const merchInventoryCheckFunc = async (merchID) => {
    try {
      const response = (await MerchApi.detail(merchID)).data.data.content;

      return response.inventory;
    } catch (error) {
      if (error?.response?.status === 401) {
        return;
      }

      errorHandler(error);
    }
  };

  return (
    <>
      <ProductPaymentSection $isMobile={isMobile} $isTablet={isTablet}>
        <PCContainer
          style={{
            width: "100%",
            marginBottom: isMobile ? 40 : 53,
          }}
        >
          <PretendardText
            style={{
              fontSize: isMobile ? 24 : 32,
              lineHeight: isMobile ? "33.6px" : "44.8px",
              fontWeight: 600,
              color: "#313131",
            }}
          >
            Thanh toán
          </PretendardText>
        </PCContainer>

        <PCContainer
          style={{
            flexDirection: isSmall ? "column" : "row",
          }}
        >
          <div
            style={{
              width: isMobile ? "100%" : 670,
              marginRight: isSmall ? 0 : 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                border: "1px solid #DCDCDC",
                borderRadius: 12,
                padding: isMobile ? 20 : "30px 28px 33px 30px",
                marginBottom: isMobile ? 24 : 40,
              }}
            >
              <CardTitle $isMobile={isMobile}>Địa chỉ</CardTitle>

              <div
                style={{
                  width: "100%",
                  height: 1,
                  background: "#DCDCDC",
                  margin: "16px 0px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 16 : 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 16,
                    lineHeight: isMobile ? "19.6px" : "23.2px",
                    color: "#000000",
                    fontWeight: 500,
                    minWidth: 96,
                    marginRight: isMobile ? 0 : 40,
                    marginBottom: isMobile ? 8 : 0,
                  }}
                >
                  Địa chỉ
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 16,
                    lineHeight: isMobile ? "19.6px" : "23.2px",
                    color: "#626262",
                    fontWeight: 400,
                  }}
                >
                  {addressDetail} {address}
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 16 : 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 16,
                    lineHeight: isMobile ? "19.6px" : "23.2px",
                    color: "#000000",
                    fontWeight: 500,
                    minWidth: 96,
                    marginRight: isMobile ? 0 : 40,
                    marginBottom: isMobile ? 8 : 0,
                  }}
                >
                  Số điện thoại
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 16,
                    lineHeight: isMobile ? "19.6px" : "23.2px",
                    color: "#626262",
                    fontWeight: 400,
                  }}
                >
                  {phone}
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginBottom: isMobile ? 16 : 20,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 16,
                    lineHeight: isMobile ? "19.6px" : "23.2px",
                    color: "#000000",
                    fontWeight: 500,
                    minWidth: 96,
                    marginRight: isMobile ? 0 : 40,
                    marginBottom: isMobile ? 8 : 0,
                  }}
                >
                  Người nhận
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: isMobile ? 14 : 16,
                    lineHeight: isMobile ? "19.6px" : "23.2px",
                    color: "#626262",
                    fontWeight: 400,
                  }}
                >
                  {name}
                </PretendardText>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: isMobile ? "flex-start" : "center",
                }}
              >
                <RightLabelCheckbox
                  checked={reAddress}
                  onChange={() => {
                    setReAddress(!reAddress);
                  }}
                  textStyle={{
                    color: "#313131",
                    fontSize: isMobile ? 14 : 16,
                  }}
                  text={"Lưu địa chỉ"}
                />
                <PretendardText
                  onClick={() => {
                    setIsADodal(true);
                  }}
                  style={{
                    width: isMobile ? "100%" : "auto",
                    textAlign: "right",
                    fontSize: isMobile ? 14 : 16,
                    lineHeight: isMobile ? "21px" : "24px",
                    marginTop: isMobile ? 13 : 0,
                    color: "#626262",
                    fontWeight: 400,
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  Thay đổi địa chỉ giao hàng
                </PretendardText>
              </div>
            </div>

            <PaymentProduct
              merchInfo={merchInfo}
              totalAmount={totalAmount}
              productAmount={productAmount}
              deliveryAmount={deliveryAmount}
            />

            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                border: "1px solid #DCDCDC",
                borderRadius: 12,
                padding: "30px 29px 36px 30px",
                marginBottom: 40,
              }}
            >
              <CardTitle>phương thức thanh toán</CardTitle>

              <div
                style={{
                  width: "100%",
                  height: 1,
                  background: "#DCDCDC",
                  margin: "16px 0px",
                }}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "23px",
                }}
              >
                {paymentMethod.map((item, index) => {
                  return (
                    <RightLabelRadio
                      checked={item.value}
                      key={index}
                      text={item.title}
                      onClick={() => {
                        payMethodClickFunc(item.title);
                      }}
                    />
                  );
                })}
              </div>
            </div> */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                border: "1px solid #DCDCDC",
                borderRadius: 12,
                padding: isMobile ? 20 : "30px 29px 30px 30px",
              }}
            >
              <CardTitle $isMobile={isMobile}>
                Sử dụng Điểm / Mã giảm giá
              </CardTitle>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: isMobile ? 16 : 10,
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#313131",
                    fontWeight: 700,
                  }}
                >
                  Số điểm có thể sử dụng
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#939393",
                    fontWeight: 700,
                  }}
                >
                  {Number(myPoint).toLocaleString()}P
                </PretendardText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-end" : "flex-start",
                }}
              >
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder={"0"}
                    value={point}
                    onChange={(e) => {
                      setPoint(e.target.value);
                    }}
                  />
                  {pointErrorM ? (
                    <PretendardText
                      style={{
                        fontWeight: 600,
                        fontSize: 12,
                        lineHeight: "16.8px",
                        color: "#F24147",
                        marginTop: 4,
                      }}
                    >
                      {pointErrorM}
                    </PretendardText>
                  ) : null}
                </div>

                <MainBorderBtn
                  onClick={() => {
                    if (pointErrorM !== "") {
                      return;
                    }

                    if (Number(point) > myInfo?.point) {
                      toast("Giá trị nhập lớn hơn số điểm đang có.");
                      setPoint("0");
                    } else if (Number(point) > Number(totalAmount)) {
                      toast("Số điểm lớn hơn giá trị sản phẩm.");
                      setPoint("0");
                    } else {
                      toast("Điểm đã được áp dụng.");
                      setUsePoint(Number(point));
                      setMyPoint(myInfo?.point - Number(point));
                      setPoint("0");
                    }
                  }}
                  style={{
                    minWidth: 141,
                    maxWidth: 141,
                    marginLeft: isMobile ? 0 : 10,
                    marginTop: isMobile ? 16 : 0,
                  }}
                >
                  <PretendardText
                    style={{
                      fontSize: 16,
                      color: "#313131",
                      fontWeight: 600,
                    }}
                  >
                    Sử dụng
                  </PretendardText>
                </MainBorderBtn>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 16,
                  marginBottom: 10,
                }}
              >
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#313131",
                    fontWeight: 700,
                  }}
                >
                  Mã giảm giá
                </PretendardText>
                <PretendardText
                  style={{
                    fontSize: 16,
                    lineHeight: "23.2px",
                    color: "#939393",
                    fontWeight: 700,
                  }}
                >
                  Tổng số {selectCouponList.length}
                </PretendardText>
              </div>

              <Select
                style={{}}
                disabled={selectCouponList.length > 0 ? false : true}
                setValue={setSelectCouponValue}
                value={selectCouponValue}
                dataList={selectCouponList}
                placeholder={"Vui lòng chọn phiếu giảm giá."}
                setAllValue={setSelectCouponAllValue}
              />
            </div>
          </div>

          <ProductPaymentRight
            handlePayment={handlePayment}
            usePoint={Number(usePoint)}
            couponAmount={Number(couponAmount)}
            totalAmount={totalAmount}
            calTotalAmount={calTotalAmount}
          />
        </PCContainer>

        <FooterBtnGroup />
      </ProductPaymentSection>

      {isADModal ? (
        <AddressModal
          setVisiable={setIsADodal}
          isScroll={false}
          onClick={addressChangeFunc}
        />
      ) : null}

      <form id="megapayForm" name="megapayForm" method="POST">
        <input type="hidden" name="merId" value="KOVIETVN01" />
        <input type="hidden" name="currency" value="VND" />
        <input type="hidden" name="amount" value={calTotalAmount} />
        <input
          type="hidden"
          name="invoiceNo"
          value={`ORDER_${moment().format("YYYYMMDD_HHmmss")}_${
            merchInfo && merchInfo.length > 0 ? merchInfo[0].merch.id : "1"
          }`}
        />
        <input
          type="hidden"
          name="goodsNm"
          value={
            merchInfo
              ? merchInfo.length > 1
                ? `${merchInfo[0].merch.title} ngoài ${merchInfo.length - 1}`
                : merchInfo[0].merch.title
              : ""
          }
        />
        <input type="hidden" name="payType" value="NO" />
        <input type="hidden" name="buyerEmail" value={myInfo?.email} />
        <input
          type="hidden"
          name="vaStartDt"
          value={`${moment().format("YYYYMMDDHHmmss")}`}
        />
        <input
          type="hidden"
          name="vaEndDt"
          value={`${moment().add(24, "minutes").format("YYYYMMDDHHmmss")}`}
        />
        {/* <input
          type="hidden"
          name="callBackUrl"
          value="http://localhost:3000/koviet/product_payment"
        />
        <input
          type="hidden"
          name="notiUrl"
          value="http://localhost:3000/koviet/product_payment"
        />
        <input type="hidden" name="reqDomain" value="http://localhost:3000" /> */}
        <input
          type="hidden"
          name="callBackUrl"
          value="https://koviet.vn/koviet/product_payment"
        />
        <input
          type="hidden"
          name="notiUrl"
          value="https://koviet.vn/koviet/product_payment"
        />
        <input type="hidden" name="reqDomain" value="https://koviet.vn" />
        <input type="hidden" name="description" value="EGXMGMYQGS" />
        <input
          type="hidden"
          name="merchantToken"
          value={sha256(
            `${timestamp}KOVIETVN01${timestamp}KOVIETVN01${calTotalAmount}9Mb//zH7jxZKPfr0JofJbKbMXiCwYDjvP+P+6yx8LiNfJtm1BDHafbTzlbF1NVHThiDhySToahNfyIapp7SjLw==`
          )}
        />
        <input type="hidden" name="userIP" value={localIp} />
        <input type="hidden" name="userLanguage" value="VN" />
        <input type="hidden" name="timeStamp" value={timestamp} />
        <input type="hidden" name="merTrxId" value={`KOVIETVN01${timestamp}`} />
        <input type="hidden" name="windowColor" value="#ef5459" />
        <input type="hidden" name="windowType" value={isSmall ? "1" : "0"} />
      </form>
    </>
  );
};

export default ProductPayment;

const ProductPaymentSection = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${(props) =>
    props.$isMobile
      ? "80px 20px 80px 20px"
      : props.$isTablet
      ? "80px 20px 80px 20px"
      : "80px 0px;"};
`;

const CardTitle = styled(PretendardText)`
  font-size: ${(props) => (props.$isMobile ? "16px" : "20px")};
  line-height: ${(props) => (props.$isMobile ? "23.2px" : "28px")};
  color: rgb(49, 49, 49);
  font-weight: 600;
`;
